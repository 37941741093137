// countryUtils.ts
import axios from 'axios';

interface IpInfoResponse {
  ip: string;
}

interface CountryInfoResponse {
  country: string;
  // 其他可能的字段
}
export enum CountryCode {
  VI = 'VN',
  ID = 'ID',
}
export async function getUserCountry(): Promise<string> {
  try {
    // 获取用户的IP地址
    const response = await axios.get<IpInfoResponse>(
      'https://api.ipify.org?format=json'
    );
    const ip = response.data.ip;

    // 根据IP地址获取国家信息
    const countryResponse = await axios.get<CountryInfoResponse>(
      `https://ipinfo.io/${ip}/json`
    );
    const country = countryResponse.data.country;
    return country;
  } catch (error) {
    console.error('Error checking country:', error);
    throw error;
  }
}
