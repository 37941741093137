import user from './user';
import allUsers from './allUsers';
import { webConfig } from './webConfig';
import payStatus from './payStatus';
import language from './language';
import country from './country';

export default {
  webConfig,
  user,
  allUsers,
  payStatus,
  language,
  country,
};
