import { CountryCode } from '@/utils/countryUtils';
import { reactive } from 'vue';

class Country {
  data?: string;
  isPhoneDisabled = false;

  constructor() {
    const storedData = localStorage.getItem('country');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.data = parsedData;
      this.isPhoneDisabled =
        parsedData.toUpperCase() === CountryCode.ID ||
        parsedData.toUpperCase() === CountryCode.VI;
    }
  }

  save(data: string) {
    this.data = data;
    localStorage.setItem('country', JSON.stringify(data));
    this.isPhoneDisabled =
      data.toUpperCase() === CountryCode.ID ||
      data.toUpperCase() === CountryCode.VI;
  }
}

export default reactive(new Country());
